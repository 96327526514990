import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ProductsMapper from "./mapper";
import { Schema } from 'rsuite';
import { cloneDeep, isArray, get, set, merge, uniq } from 'lodash'
import arrayMove from 'array-move';
import { formatErrors, parse, brandPrediction, slugify } from '../../utils/utils'

import { getAll, connectToExisting, openNew, persistChanges, create, update, changeStatus, unmap, reset, getRelated, getExisting, mergeScraped, getShops, connectToExistingAsBarcode } from './mapper-action'
import { getAll as getAllCateogries } from '../categories/categories-action'
import { getAll as getAllAttributes } from '../attributes/attributes-action'
import { getAll as getAllScraperAttributes, create as createScraperAttribute, persistChanges as persitScraperAttribute } from '../scraper-attributes-mapper/sattributes-action'
import { getAll as getAllAttributesCategories } from '../attributes/attributes-categories-action'
import { getAvailableProducts, getAvailableCategories, getAvailableBrands } from '../_base/navigation/nav-action'

import { showDrawer, closeDrawer } from '../_base/drawer/drawer-action';
import { showModal } from '../_base/modal/modal-action';
import { Product } from "../products/products-const";
import { deleteFile } from "../files/files-action"



const ProductsContainer = () => {
    const dispatch = useDispatch();
    const [formError, setFormError] = useState({});
    const products = useSelector(state => state.mapper);
    const available_websites = useSelector(state => state.base.available_selection.websites);
    const categories = useSelector(state => state.base.available_selection.categories);
    const brands = useSelector(state => state.base.available_selection.brands);
    const shops = useSelector(state => state.base.available_selection.shops);
    const mapper_shops = useSelector(state => state.mapper.shops);
    const attributes = useSelector(state => state.attributes.data);
    const attributes_categories = useSelector(state => state.attributes_categories.data);
    const product_autocomplete = useSelector(state => state.base.available_selection.products)
    const selected_website = useSelector(state => state.base.selected_website);

    const current_user = useSelector(state => state.base.user)


    useEffect(() => {
        if (selected_website.id) {
            dispatch(getAllCateogries(selected_website.id));
            dispatch(getShops(selected_website.scraper_url, selected_website.scraper_token));
            dispatch(getAll(selected_website.scraper_url, selected_website.scraper_token, {
                number: 0,
                size: 0,
                total_found: 0,
                loading: false,
                filters: {
                    mapped_status: "new"
                },
                sort: null,
                sort_by: null
            }));
        }
    }, [dispatch, selected_website]);

    const { StringType, NumberType, ArrayType, ObjectType, BooleanType, DateType } = Schema.Types;

    const model = Schema.Model({
        name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255').isRequired('This field is required.'),
        status: StringType().isOneOf(Object.values(Product.Status), `Can only be one of: ${Object.values(Product.Status)}`).isRequired('Status is required.'),
        priority: NumberType().isInteger(),
        multi_variant: BooleanType(),
        categories: ArrayType(),
        product_variations: ArrayType().of(ObjectType()).isRequired('This field is required.'),
        product_relationships: ArrayType().of(ObjectType()),
        websites: ArrayType().of(ObjectType()).isRequired('This field is required.')
    });

    const modelVariation = Schema.Model({
        name: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255').isRequired('This field is required.'),
        status: StringType().isOneOf(Object.values(Product.Status), `Can only be one of: ${Object.values(Product.Status)}`).isRequired('Status is required.'),
        sku: StringType().rangeLength(5, 255, 'The number of characters must be between 5 and 255').isRequired('This field is required.'),
        short_name: StringType().rangeLength(1, 255, 'The number of characters must be between 2 and 255'),
        search_terms: StringType().rangeLength(1, 255, 'The number of characters must be between 2 and 255'),
        page_title: StringType().rangeLength(20, 255, 'The number of characters must be between 20 and 255'),
        slug: StringType().rangeLength(2, 255, 'The number of characters must be between 2 and 255').isRequired('This field is required.'),
        meta_description: StringType().rangeLength(0, 160, 'The number of characters must be between 0 and 160'),
        bestseller: BooleanType(),
        featured: BooleanType(),
        description: StringType(),
        position: NumberType().range(0, 99, 'Min 0 - Max 99').isInteger(),
        brand: ObjectType().shape({
            id: NumberType().isInteger().isRequired('This field is required.')
        }),
        amount: NumberType().min(0, 'Min 0').isInteger().isRequired('This field is required.'),
        unit: StringType().rangeLength(1, 10, 'The number of characters must be between 1 and 10').isRequired('This field is required.'),
        standardized_amount: NumberType().min(0, 'Min 0').isInteger().isRequired('This field is required.'),
        standardized_unit: StringType().rangeLength(1, 10, 'The number of characters must be between 1 and 10').isRequired('This field is required.'),
        product_images: ArrayType(),
        product_documents: ArrayType(),
        barcodes: ArrayType(),
        prices: ArrayType(),
        product_attributes: ArrayType(),
        websites: ArrayType().of(ObjectType()).isRequired('This field is required.'),
        iframe: StringType(),
        view3d: StringType(),
        video: StringType(),
    });

    const modelBarcode = Schema.Model({
        value: StringType().isRequired('This field is required.'),
        type: StringType().isOneOf(Object.values(Product.Barcode), `Can only be one of: ${Object.values(Product.Barcode)}`).isRequired('This field is required.')
    });

    const modelRelated = Schema.Model({
        related_product: ObjectType().shape({
            id: NumberType().isInteger().isRequired('This field is required.')
        }).isRequired('This field is required.'),
        relationship_type: StringType().isOneOf(Object.values(Product.RelatedProductType), `Can only be one of: ${Object.values(Product.RelatedProductType)}`).isRequired('This field is required.')
    });


    const modelAttribute = Schema.Model({
        attribute: ObjectType().shape({
            id: NumberType().isInteger().isRequired('This field is required.')
        }).isRequired('This field is required.'),
        attribute_category: ObjectType().shape({
            id: NumberType().isInteger().isRequired('This field is required.')
        }).isRequired('This field is required.'),
        value_s: StringType(),
        value_t: StringType(),
        value_b: BooleanType(),
        value_f: NumberType(),
        value_i: NumberType().isInteger(),
        value_d: DateType(),
        qty: NumberType(),
    });

    const modelPrice = Schema.Model({
        shop: ObjectType().shape({
            id: NumberType().isInteger().isRequired('This field is required.')
        }).isRequired('This field is required.'),
        // current_price: NumberType().min(0, 'Min 0'),
        // old_price: NumberType().min(0, 'Min 0'),
        // price_per_standardized: NumberType().min(0, 'Min 0'),
        promo: BooleanType(),
        note: StringType(),
        valid_from: DateType(),
        valid_to: DateType()
    });


    const handleSave = useCallback(
        (ftype) => {
            let err = formatErrors(model.check(products[`${ftype}`]));
            err = !err ? {} : err;

            products[`${ftype}`].product_variations.forEach((pv, pvindex) => {
                let serr = formatErrors(modelVariation.check(pv))
                if (serr) {
                    for (let key in serr) {
                        err[`product_variations.${pvindex}.${key}`] = serr[key]
                    }
                    err[`product_variations.${pvindex}`] = true
                }

                pv.barcodes.forEach((br, brindex) => {
                    let berr = formatErrors(modelBarcode.check(br))
                    if (berr) {
                        for (let key in berr) {
                            err[`product_variations.${pvindex}.barcodes.${brindex}.${key}`] = berr[key]
                        }
                        err[`product_variations.${pvindex}.barcodes`] = true
                    }
                });

                pv.product_attributes.forEach((att, attrindex) => {
                    let aterr = formatErrors(modelAttribute.check(att))
                    if (aterr) {
                        for (let key in aterr) {
                            err[`product_variations.${pvindex}.product_attributes.${attrindex}.${key}`] = aterr[key]
                        }
                        err[`product_variations.${pvindex}.product_attributes`] = true
                    }
                });

                pv.prices.forEach((prr, prrindex) => {
                    let prterr = formatErrors(modelPrice.check(prr))
                    if (prterr) {
                        for (let key in prterr) {
                            err[`product_variations.${pvindex}.prices.${prrindex}.${key}`] = prterr[key]
                        }
                        err[`product_variations.${pvindex}.prices`] = true
                    }
                });
            });

            products[`${ftype}`].product_relationships.forEach((value, index) => {
                let serr = formatErrors(modelRelated.check(value))
                if (serr) {
                    for (let key in serr) {
                        err[`product_relationships.${index}.${key}`] = serr[key]
                    }
                    err['product_relationships'] = true
                }
            });

            if (Object.keys(err).length > 0) {
                console.log(err)
                setFormError(err)
                return
            }
            if (ftype === "new")
                dispatch(create(products.new, selected_website.scraper_url, selected_website.scraper_token, products.page))
            else if (ftype === "edit")
                dispatch(update(products.edit, selected_website.scraper_url, selected_website.scraper_token, products.page))
        }, [dispatch, products, model, selected_website, modelAttribute, modelBarcode, modelPrice, modelRelated, modelVariation]
    )



    const handlePageChange = useCallback(
        (sp) => {
            products.page.number = sp - 1
            dispatch(getAll(selected_website.scraper_url, selected_website.scraper_token, products.page))
        }, [dispatch, selected_website, products.page]
    )



    const handleApplyFilter = useCallback(
        (filters, selected_website) => {
            products.page.filters = filters;
            dispatch(getAll(selected_website.scraper_url, selected_website.scraper_token, products.page))
        }, [dispatch, products.page]
    )


    const handleConnectToExsiting = useCallback(
        (id, scraped_product) => {
            //dispatch(getAllAttributes(selected_website.id))
            //dispatch(getAllAttributesCategories(selected_website.id))
            //dispatch(closeDrawer('product_new'))
            //dispatch(showDrawer('product_edit'))



            dispatch(connectToExisting(id, selected_website))
        }, [dispatch, selected_website]
    )

    const handleChangeStatus = useCallback(
        (id, status) => {
            dispatch(changeStatus(id, status, selected_website.scraper_url, selected_website.scraper_token, products.page))
        }, [dispatch, selected_website, products.page]
    )

    const handleUnmap = useCallback(
        (id) => {
            dispatch(unmap(id, selected_website.scraper_url, selected_website.scraper_token, products.page))
        }, [dispatch, selected_website, products.page]
    )

    const handleNew = useCallback(
        (scraped_product) => {
            const data = cloneDeep(products["new"]);
            dispatch(persistChanges({ ...scraped_product.same_barcode_products, selected: scraped_product }, "scrape"))
            if (data.websites.length < 1) {
                data.websites.push(selected_website)
                dispatch(persistChanges(data, "new"))
            }
            data.product_variations.forEach((pv, index) => {
                //autopoulate website
                if (pv.websites.length < 1) {
                    data.product_variations[`${index}`].websites.push(selected_website)
                }
                //autopopulate barcodes
                let barcodes = [];
                scraped_product.same_barcode_products.docs.forEach(prod => {
                    if (prod.barcodes && prod.barcodes.length > 0)
                        barcodes = merge(barcodes, prod.barcodes);
                })
                barcodes = uniq(barcodes);
                barcodes.forEach(bar => {

                    data.product_variations[`${index}`].barcodes.push({ value: bar, type: "EAN" })
                    //console.log(bar)
                    //console.log(+bar)
                    if (+bar)
                        data.product_variations[`${index}`].sku = bar;
                })
                //autopopulate brands
                data.product_variations[`${index}`].brand = brandPrediction(scraped_product.same_barcode_products.docs, brands)
                data.product_variations[`${index}`].related_scrape_products = scraped_product.same_barcode_products.docs;
                dispatch(persistChanges(data, "new"))
            })


            dispatch(getAllAttributes(selected_website.id))
            dispatch(getAllAttributesCategories(selected_website.id))
            dispatch(getAllScraperAttributes(selected_website.id))

            dispatch(openNew())
        }, [dispatch, selected_website, products, brands]
    )

    const handleFormChange = useCallback(
        (form, ftype) => {
            let data = cloneDeep(parse(form))
            if (isArray(data.permissions))
                data.permissions = data.permissions.join(' ')

            setFormError({})
            dispatch(persistChanges(data, ftype))
        }, [dispatch]
    )

    const removeFormRow = useCallback(
        (key, index, ftype) => {
            const data = cloneDeep(products[`${ftype}`]);
            const arr = get(data, key);
            arr.splice(index, 1);
            dispatch(persistChanges(data, ftype))
        }, [dispatch, products]
    )

    const addFormRow = useCallback(
        (key, ftype, initial_state = {}, index) => {
            const istate = cloneDeep(initial_state);
            const data = cloneDeep(products[`${ftype}`]);
            let arr = get(data, key);
            if (!arr) {
                set(data, key, []);
                arr = get(data, key);
            }
            if (typeof index === 'number') {
                arr.splice(index, 0, istate); // Inserts at the specified index
            } else {
                arr.push(istate); // Appends to the end if no index provided
            }
            dispatch(persistChanges(data, ftype))
        }, [dispatch, products]
    );

    const handleCancel = useCallback(
        (ftype) => {
            dispatch(reset());
            dispatch(closeDrawer('product_' + ftype))
        }, [dispatch]
    )

    const handleShowModal = useCallback(
        (type) => {
            dispatch(showModal(type))
        }, [dispatch]
    )

    const handleAssignImageFile = useCallback(
        (file, ftype, vindex, position = null) => {
            if (position !== null) {
                dispatch(deleteFile(products[`${ftype}`].product_variations[`${vindex}`].product_images[position].file));
                products[`${ftype}`].product_variations[`${vindex}`].product_images[position] = {
                    file: file,
                    position: position
                }

            } else {
                products[`${ftype}`].product_variations[`${vindex}`].product_images.push({
                    file: file,
                    position: products[`${ftype}`].product_variations[`${vindex}`].product_images.length
                });
            }

            dispatch(persistChanges(products[`${ftype}`], ftype));
        }, [dispatch, products]
    )

    const handleDeleteImageFile = useCallback(
        (file, ftype, vindex, imgindex) => {
            const data = cloneDeep(products[`${ftype}`]);
            data.product_variations[`${vindex}`].product_images.splice(imgindex, 1);
            data.product_variations[`${vindex}`].product_images.map((img, index) => {
                img.position = index;
                return img;
            });
            dispatch(persistChanges(data, ftype));
            dispatch(deleteFile(file));
            dispatch(getAll(selected_website.scraper_url, selected_website.scraper_token, products.page));
        }, [dispatch, products, selected_website]
    )


    const handleAssignDocumentFile = useCallback(
        (file, ftype, vindex) => {
            products[`${ftype}`].product_variations[`${vindex}`].product_documents.push({
                file: file,
                position: products[`${ftype}`].product_variations[`${vindex}`].product_documents.length
            });

            dispatch(persistChanges(products[`${ftype}`], ftype));
        }, [dispatch, products]
    )

    const handleDeleteDocumentFile = useCallback(
        (file, ftype, vindex, index) => {
            const data = cloneDeep(products[`${ftype}`]);
            data.product_variations[`${vindex}`].product_documents.splice(index, 1);
            data.product_variations[`${vindex}`].product_documents.map((doc, index) => {
                doc.position = index;
                return doc;
            });
            dispatch(persistChanges(data, ftype));
            dispatch(deleteFile(file));
            dispatch(getAll(selected_website.scraper_url, selected_website.scraper_token, products.page));
        }, [dispatch, products, selected_website.id]
    )

    const handleReorderProductVariations = useCallback(
        (oldIndex, newIndex, ftype) => {
            const data = cloneDeep(products[`${ftype}`]);
            data.product_variations = arrayMove(data.product_variations, oldIndex, newIndex);
            data.product_variations.map((pv, index) => {
                pv.position = index;
                return pv;
            });
            dispatch(persistChanges(data, ftype))
        }, [dispatch, products]
    )

    const handleReorderImages = useCallback(
        (oldIndex, newIndex, vindex, ftype) => {
            const data = cloneDeep(products[`${ftype}`]);
            data.product_variations[`${vindex}`].product_images = arrayMove(data.product_variations[`${vindex}`].product_images, oldIndex, newIndex)
            data.product_variations[`${vindex}`].product_images.map((img, index) => {
                img.position = index;
                return img;
            });
            dispatch(persistChanges(data, ftype));
        }, [dispatch, products]
    )

    const handleReorderDocuments = useCallback(
        (oldIndex, newIndex, vindex, ftype) => {
            const data = cloneDeep(products[`${ftype}`]);
            data.product_variations[`${vindex}`].product_documents = arrayMove(data.product_variations[`${vindex}`].product_documents, oldIndex, newIndex)
            data.product_variations[`${vindex}`].product_documents.map((doc, index) => {
                doc.position = index;
                return doc;
            });
            dispatch(persistChanges(data, ftype));
        }, [dispatch, products]
    )

    const handleAutocompleteChange = useCallback(
        (value) => {
            if (value)
                dispatch(getAvailableProducts(selected_website.id, value));
        }, [dispatch, selected_website]
    )

    const handleNameChange = useCallback(
        (value,) => {
            if (value) {
                dispatch(getExisting(selected_website.id, value));
                let barcodes = [];
                for (const prod of products.scrape.docs) {
                    if (prod.barcodes)
                        barcodes = merge(barcodes, prod.barcodes);
                }
                barcodes = uniq(barcodes)
                dispatch(getRelated(selected_website.scraper_url, selected_website.scraper_token, value, barcodes));
            }
        }, [dispatch, selected_website, products.scrape.docs]
    )

    const handleVariationChange = useCallback(
        (scraped_product, action) => {
            let barcodes = [];
            if (action === "add") {
                let new_barcodes = [];
                scraped_product.barcodes.forEach(br => {
                    new_barcodes.push({ value: br, type: 'EAN' })
                    barcodes.push(br)
                })

                addFormRow(`product_variations`, 'new', {
                    sku: new_barcodes[0] ? new_barcodes[0].value : "",
                    name: scraped_product.name,
                    slug: slugify(scraped_product.name) + (selected_website.product_slug_addition ? selected_website.product_slug_addition : ""),
                    short_name: "",
                    status: Product.Status.ACTIVE,
                    bestseller: null,
                    featured: null,
                    description: "",
                    position: 0,
                    brand: brandPrediction(scraped_product.same_barcode_products.docs, brands),
                    amount: 1,
                    unit: "kom",
                    standardized_amount: 1,
                    standardized_unit: "kom",
                    product_images: [],
                    product_documents: [],
                    barcodes: new_barcodes,
                    prices: [],
                    product_attributes: [],
                    websites: [selected_website],
                    related_scrape_products: scraped_product.same_barcode_products.docs
                });
            }

            for (const pv of products.new.product_variations) {
                for (const barcode of pv.barcodes) {
                    if (barcode.value) {
                        barcodes.push(barcode.value);
                    }
                }
            }
            barcodes = uniq(barcodes)
            dispatch(getRelated(selected_website.scraper_url, selected_website.scraper_token, products.new.name, barcodes));
        }, [dispatch, selected_website, products.new, brands, addFormRow]
    )


    const handleMergeScraped = useCallback(
        (scraped, v_id) => {
            products.new.product_variations[v_id].barcodes = [...products.new.product_variations[v_id].barcodes, { value: scraped.barcodes[0], type: 'EAN' }]

            products.new.product_variations[v_id].barcodes.forEach(bar => {
                if (!products.new.product_variations[v_id].sku && +bar.value)
                    products.new.product_variations[v_id].sku = bar.value;
            })

            products.new.product_variations[v_id].related_scrape_products = [...products.new.product_variations[v_id].related_scrape_products, ...scraped.same_barcode_products.docs]
            dispatch(persistChanges(products.new, "new"))
            dispatch(mergeScraped(scraped, v_id))
            let barcodes = [];
            for (const pv of products.new.product_variations) {
                for (const barcode of pv.barcodes) {
                    if (barcode.value) {
                        barcodes.push(barcode.value);
                    }
                }
            }
            barcodes = uniq(barcodes)
            dispatch(getRelated(selected_website.scraper_url, selected_website.scraper_token, products.new.name, barcodes));

        }, [dispatch, products, selected_website]
    )

    const handleRefreshInputs = useCallback(
        () => {
            dispatch(getAvailableBrands(selected_website.id))
            dispatch(getAvailableCategories(selected_website.id))
            dispatch(getAllAttributes(selected_website.id))
            dispatch(getAllAttributesCategories(selected_website.id))
            dispatch(getAllScraperAttributes(selected_website.id))
        }, [dispatch, selected_website]
    )







    const handleConnectToExsitingAsBarcode = useCallback(
        (product_id, variation_id) => {
            dispatch(connectToExistingAsBarcode(product_id, variation_id, selected_website.scraper_url, selected_website.scraper_token, products.page))
        }, [dispatch, selected_website, products]
    )


    const handleSortChange = useCallback(
        (sort_by, sort) => {
            products.page.sort = sort;
            products.page.sort_by = sort_by;
            dispatch(getAll(selected_website.scraper_url, selected_website.scraper_token, products.page))

            // dispatch(getAll(selected_website.scraper_url, selected_website.scraper_token, {
            //     number: 0,
            //     size: 0,
            //     total_found: 0,
            //     loading: false,
            //     filters: {
            //         mapped_status: "new"
            //     },
            //     sort: null,
            //     sort_by: null
            // }));

        }, [dispatch, selected_website.id, products.page]
    )


    return (
        <>
            <ProductsMapper
                products={products}
                categories={categories}
                brands={brands}
                shops={shops}
                available_websites={available_websites}
                handlePageChange={handlePageChange}
                handleNew={handleNew}
                handleChangeStatus={handleChangeStatus}
                handleFormChange={handleFormChange}
                removeFormRow={removeFormRow}
                addFormRow={addFormRow}
                handleSave={handleSave}
                handleCancel={handleCancel}
                handleShowModal={handleShowModal}
                formError={formError}
                model={model}
                handleAssignImageFile={handleAssignImageFile}
                handleDeleteImageFile={handleDeleteImageFile}
                handleApplyFilter={handleApplyFilter}
                handleReorderProductVariations={handleReorderProductVariations}
                handleReorderImages={handleReorderImages}
                handleAutocompleteChange={handleAutocompleteChange}
                product_autocomplete={product_autocomplete}
                attributes={attributes}
                attributes_categories={attributes_categories}
                selected_website={selected_website}
                handleNameChange={handleNameChange}
                handleConnectToExsiting={handleConnectToExsiting}
                handleVariationChange={handleVariationChange}
                handleRefreshInputs={handleRefreshInputs}
                handleMergeScraped={handleMergeScraped}
                handleAssignDocumentFile={handleAssignDocumentFile}
                handleDeleteDocumentFile={handleDeleteDocumentFile}
                handleReorderDocuments={handleReorderDocuments}
                current_user={current_user}
                mapper_shops={mapper_shops}
                handleConnectToExsitingAsBarcode={handleConnectToExsitingAsBarcode}
                handleUnmap={handleUnmap}
                handleSortChange={handleSortChange}
            />
        </>
    )
}

export default ProductsContainer;